import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ClaimService } from '../shared/claimservice';
import { catchError, map } from 'rxjs/operators';
import { of } from "rxjs";
import { SessionService } from "./sessionservice";
import { OktaAuthService } from "@okta/okta-angular";

@Injectable()
export class ClaimResolver implements Resolve<any>{
  constructor(private claimService: ClaimService, 
                private _oktaAuthService: OktaAuthService,
                private sessionService:SessionService) {    

  }
  resolve(route: ActivatedRouteSnapshot) {
    if(this.sessionService.userOrg === '' || this.sessionService.userOrg === undefined)
      this.sessionService.userOrg = this._oktaAuthService.authStateManager._authState.idToken?.claims.orgval;    
    if(this.sessionService.userId === '' || this.sessionService.userId === undefined)
      this.sessionService.userId = this._oktaAuthService.authStateManager._authState.idToken?.claims.email ?? '';
    
    const id = route.paramMap.get('id');
    return this.claimService.getClaim(id).pipe(map(result => result),
    catchError((error) => {
        const message = `Retrieval error: ${error.message}`;
      //console.error(error);
      return of({ error: message });
    }));
  }
}
